import { defineStore } from "pinia";

export const useAccountStore = defineStore("accountStore", {
	
  state: () => ({

    http : useNuxtApp().$http,
    
    query : {},

    accounts : []
    
	}),

	actions: {

    async getAccounts() {
      
      const data = await this.http.index("account/account", this.query);

      this.accounts = data.accounts
    },
	},
});
