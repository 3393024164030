import { defineStore } from "pinia";

export const useDrawerStore = defineStore("drawerStore", {

  state: () => ({

    http: useNuxtApp().$http,

    accountStore: useAccountStore(),

    query: {},

    account: null,

    activeShift: null,

    selectedShift: null,

    accountTransactions: { data: [] },

    resume: {}

  }),

  getters: {

    isLastShift() {
      return this.selectedShift?.id != this.activeShift?.id
    },

    isActiveShift() {
      return this.selectedShift?.id == this.activeShift?.id
    },

    isOpen() {
      return this.activeShift != null && this.activeShift.status == 'open'
    },

    isClosed() {
      return !this.isOpen
    },

    sumPaymentMethodCash() {
      return this.resume.sumByPaymentMethod?.filter(item => item.payment_method.payment_method == 'cash').reduce((a, b) => a + Number(b.value_sum), 0)
    }
  },

  actions: {

    async getAccount() {

      await this.accountStore.getAccounts()

      this.account = this.accountStore.accounts[0]

      if (this.account.open_shift) {
        
        this.activeShift = this.account.open_shift

        this.selectedShift = this.account.open_shift
      
      } else {
        this.activeShift = null

        this.selectedShift = null
      }
    },

    async getTransactions() {

      this.query.account_id = this.account.id

      this.query.shift_id = this.selectedShift.id

      const data = await this.http.index("account/account-transaction", this.query);

      this.resume = {
        sumByPaymentMethod: data.sumByPaymentMethod,
        sumByOrigin: data.sumByOrigin,
      }
    },

    async openShift(params) {

      const data = await this.http.store("account/shift", { account_id: this.account.id, value: params.value });

      this.currentShift = data.shift
    },

    async closeShift(params) {

      params.account_id = this.account.id

      params.shift_id = this.activeShift.id

      const data = await this.http.update("account/shift", this.account.id, params);

    },

    async reopenShift(shift_id) {

      return await this.http.update("account/reopen-shift", shift_id);

    },

    async addValueToDrawer(params) {

      params.account_id = this.account.id

      params.shift_id = this.activeShift.id

      return await this.http.store("account/add-value-to-drawer", params);
    },

    async removeValueFromDrawer(params) {

      params.account_id = this.account.id

      params.shift_id = this.activeShift.id

      return await this.http.store("account/remove-value-from-drawer", params);
    },

    async payExpense(params) {

      params.account_id = this.account.id

      params.shift_id = this.activeShift.id

      return await this.http.store("account/pay-expense", params);

    },

    async cancelTransaction(id) {

      await this.http.destroy("account/account-transaction", id);

    },


    async setActiveShift(shift) {

      this.selectedShift = JSON.parse(JSON.stringify(this.activeShift))
    },

  },
});
